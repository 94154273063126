import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { lightGreen, red, grey } from "@material-ui/core/colors";
import Schedule from "../components/Schedule";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: 60,
    backgroundImage: "url(/AdobeStock_200826934.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    minHeight: 200,
  },
  secondary: {
    fontWeight: 400,
    fontFamily: "gelo, serif",
  },
  primary: {
    color: red[700],
    fontFamily: "gelo, serif",
    fontWeight: 700,
  },
  map: {
    width: "100%",
    minHeight: 300,
  },
  schedule: {
    backgroundColor: grey[100],
    padding: theme.spacing(2),
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: "000",
    backgroundColor: lightGreen[700],
    "&:hover": {
      backgroundColor: lightGreen[900],
    },
  },
}))(Button);

export default function Home() {
  const classes = useStyles();

  return (
    <>
      <Paper elevation={0} className={classes.paper} square>
        <Typography variant="h5" component="h2" className={classes.secondary}>
          Learning should be fun...
        </Typography>
        <Typography variant="h3" component="h2" className={classes.primary}>
          Trust us, it will be!
        </Typography>
        <ColorButton
          variant="contained"
          color="primary"
          href="/schedule-and-programs#program"
        >
          Find out why
        </ColorButton>
      </Paper>
      <iframe
        className={classes.map}
        frameborder={0}
        scrolling="no"
        marginheight={0}
        marginwidth={0}
        src="https://maps.google.com/maps?q=65%20S%20Center%20Street%2C%20Midway%20UT%2084049&amp;t=m&amp;z=16&amp;output=embed&amp;iwloc=near"
        title="65 S Center Street, Midway UT 84049"
        aria-label="65 S Center Street, Midway UT 84049"
      ></iframe>
      <div className={classes.schedule}>
        <Schedule />
      </div>
    </>
  );
}
