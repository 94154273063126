import React from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { lightGreen, red } from "@material-ui/core/colors";
import Schedule from "../components/Schedule";

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: "gelo, serif",
    fontWeight: 700,
    color: red[700],
  },
  link: {
    textDecoration: 'none',
  },
  primary: {
    fontFamily: "gelo, serif",
    fontWeight: 700,
  },
  days: {
    fontWeight: 200,
  },
  secondary: {
    fontFamily: "gelo, serif",
    fontWeight: 700,
  },
  halfDays: {
    padding: theme.spacing(2),
    height: "100%",
    backgroundColor: lightGreen[100],
  },
  fullDays: {
    padding: theme.spacing(2),
    height: "100%",
    backgroundColor: lightGreen[200],
  },
  extendedDays: {
    padding: theme.spacing(2),
    height: "100%",
    backgroundColor: lightGreen[300],
  },
  ul: {
    listStyleType: "none",
    "&>li": {
      padding: theme.spacing(1),
    },
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export default function SchedulePrograms() {
  const classes = useStyles();

  return (
    <>
      <Schedule />
      <Divider className={classes.divider} />
      <a id="program" href="#program" className={classes.link}>
        <Typography variant="h4" component="h1" className={classes.header}>
          Our Program
        </Typography>
      </a>
      <Typography variant="h6" component="h2" className={classes.secondary}>
        We Believe:
      </Typography>
      <Typography variant="body1" gutterBottom>
        <ul>
          <li>Curiosity should be encouraged</li>
          <li>Learning should be fun</li>
          <li>Structure and routine matter</li>
          <li>Safety is our primary responsibility</li>
        </ul>
      </Typography>
      <Typography variant="h6" component="h2" className={classes.secondary}>
        What is Play-Based learning?
      </Typography>
      <Typography variant="body1" gutterBottom>
        Play-based preschools encourage kids to be self-directed learners
        through exploration, problem solving, and the development of curiosity.
        Traditional curriculum, which encourages memorization more than
        learning, inhibits the development of initiative and independence.
        Play-based programming promotes deep learning. Decades of research in
        developmental psychology shows that play is the most effective way for
        young children to learn, develop social skills, and regulate strong
        emotions. This research also suggests that insufficient playtime during
        early learning leads to decreased empathy and sensitivity among young
        people. Play-based learning is the number one contributor to the
        development of creative problem solving skills, encouraging children to
        formulate original thought from a young age. At Wasatch Learn & Play, we
        recognize that children display the qualities necessary for deep
        learning naturally. All we need to do is create an environment where
        they can exercise those skills and grow. At the same time, we recognize
        our responsibility to help children develop a sense of routine and
        accountability. Our program doesn’t sacrifice one for the other!
      </Typography>
      <Typography variant="h6" component="h2" className={classes.secondary}>
        Enrolling Now!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Wasatch Learn & Play is a play-based learning environment for two-,
        three-, and four-year-olds in the Heber Valley and surrounding areas.
        Our programming is based on the belief that adventure and imagination
        are critical to early learning. We incorporate indoor and outdoor
        playtime and encourage children to safely explore their surroundings and
        engage their imaginations. Our program is licensed by the State of Utah,
        Office of Childcare.
      </Typography>
    </>
  );
}
