import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Activities from "./routes/Activities";
import ContactUs from "./routes/ContactUs";
import Documents from "./routes/Documents";
import FAQ from "./routes/FAQ";
import Home from "./routes/Home";
import SchedulePrograms from "./routes/SchedulePrograms";
import { ThemeProvider } from "styled-components";
import { createMuiTheme } from "@material-ui/core/styles";
import NoSsr from "@material-ui/core/NoSsr";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import lightGreen from "@material-ui/core/colors/lightGreen";

const theme = createMuiTheme({
  palette: {
    primary: lightGreen,
  },
});

export default function App() {
  return (
    <NoSsr>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Container>
          <Router>
            <Grid container>
              <Grid item xs={12}>
                <Header />
              </Grid>
              <Grid item xs={12}>
                <Switch>
                  <Route path="/activities">
                    <Activities />
                  </Route>
                  <Route path="/contact-us">
                    <ContactUs />
                  </Route>
                  <Route path="/documents">
                    <Documents />
                  </Route>
                  <Route path="/schedule-and-programs">
                    <SchedulePrograms />
                  </Route>
                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
              </Grid>
              <Grid item xs={12}>
                <Footer />
              </Grid>
            </Grid>
          </Router>
        </Container>
      </ThemeProvider>
    </NoSsr>
  );
}
