import React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import { lightGreen, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
  },
  header: {
    fontFamily: 'gelo, serif',
    fontWeight: 700,
    color: red[700],
  },
  primary: {
    fontFamily: 'gelo, serif',
    fontWeight: 700
  },
  days: {
    fontWeight: 200
  },
  secondary: {
    fontFamily: 'gelo, serif',
    fontWeight: 700
  },
  halfDays: {
    padding: theme.spacing(2),
    height: '100%',
    backgroundColor: lightGreen[100],
  },
  fullDays: {
    padding: theme.spacing(2),
    height: '100%',
    backgroundColor: lightGreen[200],
  },
  extendedDays: {
    padding: theme.spacing(2),
    height: '100%',
    backgroundColor: lightGreen[300],
  },
  ul: {
    listStyleType: 'none',
    '&>li': {
      padding: theme.spacing(1),
    }
  }
}));

export default function SchedulePrograms() {
  const classes = useStyles();

  return (
    <>
      <a id="schedule" href="/schedule-and-programs#schedule" className={classes.link}>
        <Typography variant="h4" component="h1" className={classes.header}>
          Our Schedule
        </Typography>
      </a>
      <Typography variant="h6" component="h2" className={classes.secondary}>
        Year-round Program
      </Typography>
      <Typography variant="body1" gutterBottom>
        Wasatch Learn & Play offers Monday-Thursday classes, with a morning and an afternoon group.
      </Typography>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="stretch">
        <Grid item xs={12} sm={6}>
          <Paper
            square
            elevation={0}
            className={classes.fullDays}>
            <Typography variant="h6" component="h3" className={classes.secondary}>
              Full Days <span className={classes.days}>(9:00am-3:00pm)</span>
            </Typography>
            <ul className={classes.ul}>
              <li>Monday-Thursday - $700</li>
            </ul>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            square
            elevation={0}
            className={classes.extendedDays}>
            <Typography variant="h6" component="h3" className={classes.secondary}>
              Extended Days <span className={classes.days}>(8:30am-5:00pm)</span>
            </Typography>
            <ul className={classes.ul}>
              <li>Monday-Thursday - $900</li>
            </ul>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}