import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: "gelo, serif",
    fontWeight: 700,
    color: red[700],
  },
  primary: {
    fontFamily: "gelo, serif",
    fontWeight: 700,
  },
  secondary: {
    fontFamily: "gelo, serif",
    fontWeight: 700,
  },
}));

export default function ContactUs() {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [fromEmail, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const sendEmail = (event) => {
    const email= 'wasatchlearnandplay@gmail.com';
    const body= `Phone: ${phone}%0D%0AEmail: ${email}%0D%0AMessage:%0D%0A${message}`
    const subject = 'Wasatch Learn %26 Plan Website Inquery';
    window.open(`mailto:${email}?subject=${subject}&body=${body}`);
    event.preventDefault();
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item >
          <img src="/chick.png" alt="Baby chicken." height={70}/>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h1" className={classes.header}>
            Contact Us
          </Typography>
          <Typography variant="h5" component="h2" className={classes.secondary}>
            Please feel free to use any of the contact methods below.
          </Typography>
        </Grid>
      </Grid>
      <p>
        <Typography variant="h5" component="h2" className={classes.secondary}>
          Contact Form
        </Typography>
      </p>
      <form method="post" onSubmit={sendEmail}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="filled"
              fullWidth
              type="text"
              label="Name"
              id="name"
              placeholder="Name"
              required="required"
              value={name}
              onChange={({target}) => setName(target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="filled"
              fullWidth
              size="1"
              type="email"
              label="Email"
              value={fromEmail}
              onChange={({target}) => setEmail(target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="filled"
              fullWidth
              size="1"
              type="text"
              label="Phone Number"
              id="phone"
              placeholder="Phone Number"
              required="required"
              aria-required="true"
              value={phone}
              onChange={({target}) => setPhone(target.value)}
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
              variant="filled"
              fullWidth
              multiline
              label="Message"
              id="message"
              rows="4"
              placeholder="Message"
              required="required"
              value={message}
              onChange={({target}) => setMessage(target.value)}
            />
          </Grid>
          <Grid item xs={12} >
            <Button variant="contained" type="submit">Send Message</Button>
          </Grid>
        </Grid>
      </form>

      <p>
        <Typography variant="h5" component="h2" className={classes.secondary}>
          General Contact Information
        </Typography>
        <Typography variant="body1">
          Phone: <a href="tel:435-777-5175">(435) 777-5175</a>
        </Typography>
        <Typography variant="body1">
          Email: <a href="mailto:wasatchlearnandplay@gmail.com">wasatchlearnandplay@gmail.com</a>
        </Typography>
      </p>

      <p>
        <Typography variant="h5" component="h2" className={classes.secondary}>
          Address
        </Typography>
        <Typography variant="body1">
          65 S Center St.
        </Typography>
        <Typography variant="body1">
          Midway, UT 84049
        </Typography>
      </p>
    </>
  );
}
