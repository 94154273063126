import React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import IconButton from '@material-ui/core/IconButton';
import Instagram from '@material-ui/icons/Instagram';
import Facebook from '@material-ui/icons/Facebook';
import PhoneAndroid from '@material-ui/icons/PhoneAndroid';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: grey[100],
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  ul: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    textAlign: "left",
    "&>li": {
      display: "inline-block",
      padding: theme.spacing(1),
    },
  },
  copyright: {
    margin: theme.spacing(1),
  },
  phone: {
    textAlign: "right",
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <Grid container className={classes.footer}>
      <Grid item xs={6}>
        <Grid container className={classes.copyright}>
          <Grid item xs={12}>
            <nav>
              <ul className={classes.ul}>
                <li>
                  <Link to="/schedule-and-programs">Schedule and Programs</Link>
                </li>
                <li>
                  <Link to="/documents">Documents</Link>
                </li>
                <li>
                  <Link to="/activities">Activities</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </nav>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.header} gutterBottom>
              &copy; 2019-{new Date().getFullYear()} Wasatch Learn & Play, LLC |
              All rights Reserved.
            </Typography>
          </Grid>
        </Grid>

      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" className={classes.phone} gutterBottom>
          <IconButton
            href="https://www.facebook.com/wasatchlearn/"
            target="_tab"
            className={classes.button}
            aria-label="Delete"
          >
            <Facebook />
          </IconButton>
          <IconButton
            href="https://www.instagram.com/wasatchlearn/"
            target="_tab"
            className={classes.button}
            aria-label="instagram">
            <Instagram />
          </IconButton>
          <IconButton
            href="tel:435-777-5175"
            className={classes.button}
            aria-label="call us"
          >
            <PhoneAndroid />
          </IconButton>
        </Typography>
      </Grid>

    </Grid>
  );
}
