import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(2),
    flexGrow: 1,
  },
  menuButton: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export default function Header() {
  const [state, setState] = useState({
    open: false,
  });

  const classes = useStyles();

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, open });
  };

  return (
    <Toolbar>
      <Grid container>
        <Grid item className={classes.title}>
          <Link to="/">
            <img
              width="300px"
              src="color_logo.png"
              alt="Wasatch Learn & Play Logo"
            />
          </Link>
        </Grid>
        <Grid item>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          <SwipeableDrawer
            anchor="right"
            open={state.open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <List>
              <ListItem>
                <ListItemText>
                  <Link
                    onClick={toggleDrawer(false)}
                    to="/schedule-and-programs"
                  >
                    Schedule and Programs
                  </Link>
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText>
                  <Link onClick={toggleDrawer(false)} to="/documents">
                    Documents
                  </Link>
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText>
                  <Link onClick={toggleDrawer(false)} to="/activities">
                    Activities
                  </Link>
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText>
                  <Link onClick={toggleDrawer(false)} to="/contact-us">
                    Contact Us
                  </Link>
                </ListItemText>
              </ListItem>
            </List>
          </SwipeableDrawer>
        </Grid>
      </Grid>
    </Toolbar>
  );
}
