import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: "gelo, serif",
    fontWeight: 700,
    color: red[700],
  },
  secondary: {
    fontFamily: "gelo, serif",
    fontWeight: 700,
  },
}));

export default function Activities() {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item >
          <img src="/faun.png" alt="Faun." height={50}/>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h1" className={classes.header}>
            Activities
          </Typography>
          <Typography variant="h5" component="h2" className={classes.secondary}>
            Check out our social media pages for updates.
          </Typography>
        </Grid>
      </Grid>

      <a href="https://www.instagram.com/wasatchlearn/" target="_tab">See our Instagram</a>
    </>
  );
}
