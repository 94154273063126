import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: "gelo, serif",
    fontWeight: 700,
    color: red[700],
  },
  primary: {
    fontFamily: "gelo, serif",
    fontWeight: 700,
  },
  secondary: {
    fontFamily: "gelo, serif",
    fontWeight: 700,
  },
}));

export default function Documents() {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item >
          <img src="/monkey.png" alt="Monkey blowing bubbles." height={70}/>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h1" className={classes.header}>
			PDF Documents
          </Typography>
          <Typography variant="h5" component="h2" className={classes.secondary}>
			When you come to register your child for one of our groups, we'll
			require some paperwork from you.
          </Typography>
        </Grid>
      </Grid>
      <p>
        <Typography variant="body1">
          If you want to get a head start on the paperwork, review it below:
        </Typography>
      </p>
      <p>
        <a href="/pdf-documents/Admission-Agreement.pdf" target="_tab">
          Child Enrollment Form
        </a>
      </p>
      <p>
        <a href="/pdf-documents/StateReqImmunization_USIR.pdf" target="_tab">
          Immunization Form
        </a>
      </p>
      <p>
        <a href="/pdf-documents/StateHealthAssessment.pdf" target="_tab">
          Health Information Form
        </a>
      </p>
    </>
  );
}
