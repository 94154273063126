import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: "gelo, serif",
    fontWeight: 700,
    color: red[700],
  },
  primary: {
    fontFamily: "gelo, serif",
    fontWeight: 700,
  },
  secondary: {
    fontFamily: "gelo, serif",
    fontWeight: 700,
  },
}));

export default function FAQ() {
  const classes = useStyles();

  return (
    <>
    <Grid container spacing={2}>
      <Grid item >
        <img src="/bear.png" alt="Bear raising hand." height={70}/>
      </Grid>
      <Grid item>
        <Typography variant="h4" component="h1" className={classes.header}>
          We All Have Questions
        </Typography>
        <Typography variant="h5" component="h2" className={classes.secondary}>
          Maybe we can answer some of yours!
        </Typography>
      </Grid>
    </Grid>
      <p>
        <Typography variant="h6" className={classes.secondary}>
          How often do groups meet?
        </Typography>
        <Typography variant="body1">
          We have morning and afternoon groups available for Monday/Wednesday or
          Tuesday/Thursday schedules. Complete schedules are available on our
          homepage.
        </Typography>
      </p>

      <p>
        <Typography variant="h6" className={classes.secondary}>
          How much is tuition?
        </Typography>
        <Typography variant="body1">
          Tuition during the school year is $175 per child, per month. We also
          require a $150 fee, once per school year, to cover the cost of
          registration and supplies.
        </Typography>
      </p>

      <p>
        <Typography variant="h6" className={classes.secondary}>
          Do you have groups on Friday?
        </Typography>
        <Typography variant="body1">
          As of now, we do not. We will update the community if Friday groups
          become available in the future.
        </Typography>
      </p>

      <p>
        <Typography variant="h6" className={classes.secondary}>
          Can my child come every day of the week?
        </Typography>
        <Typography variant="body1">
          Sure, you can register your child for the M/W group and the T/Th
          groups at the same time, at $175/group/month ($350/month). The $150
          registration fee will only be charged once in this case.
        </Typography>
      </p>

      <p>
        <Typography variant="h6" className={classes.secondary}>
          Do you have summer programming?
        </Typography>
        <Typography variant="body1">
          Yes, we are planning to have summer camps for your children if we open
          this summer! The tentative dates of these camps are available on our
          homepage; when we open you’ll know.
        </Typography>
      </p>

      <p>
        <Typography variant="h6" className={classes.secondary}>
          How can I keep track of my child's progress?
        </Typography>
        <Typography variant="body1">
          We use the Brightwheel app to keep parents in the Wasatch Learn & Play
          community in the loop. When you register your child, we’ll take you
          through Brightwheel and get you set up.
        </Typography>
      </p>

      <p>
        <Typography variant="h6" className={classes.secondary}>
          What is your pickup and drop off policy?
        </Typography>
        <Typography variant="body1">
          Please drop your child off within a 15 minute window before the
          beginning of your group. Please use the traffic loop according to
          signs posted; enter at the south, exit at the north. We appreciate
          your courtesy and patience. The safety of our children is our primary
          concern.
        </Typography>
      </p>

      <p>
        <Typography variant="h6" className={classes.secondary}>
          My child takes medication. How is this handled?
        </Typography>
        <Typography variant="body1">
          We don’t administer prescription medications. Please make sure your
          child takes scheduled medications at home. We do have a protocol for
          first aid and emergency care that we will review with you during
          registration.
        </Typography>
      </p>
    </>
  );
}
